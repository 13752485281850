@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?w9hf87');
  src:  url('fonts/icomoon.eot?w9hf87#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?w9hf87') format('truetype'),
    url('fonts/icomoon.woff?w9hf87') format('woff'),
    url('fonts/icomoon.svg?w9hf87#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-thin-left:before {
  content: "\e900";
}
.icon-chevron-thin-right:before {
  content: "\e901";
}
.icon-grid:before {
  content: "\e902";
}
.icon-info:before {
  content: "\e905";
}
.icon-phone2:before {
  content: "\e906";
}
.icon-twitter:before {
  content: "\e907";
}
.icon-heart:before {
  content: "\e903";
}
.icon-heart-outline:before {
  content: "\e904";
}


body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  color: #555;
}
img {
  max-width: 100%;
}

h1, h2 {
  text-transform: uppercase;
  font-family: 'Poiret One', cursive;
  color: #f4511e;
}
h1 {
  margin: .5rem 0;
  font-size: 3rem;
}
h2 {
  font-size: 1.5rem;
}
a {
  text-decoration: none;
}
a:link, a.visited {
  color: #c62828;
}
a.hover, a.active {
  color: #f4511e;
}
ul {
  list-style-type: none;
  padding: 0;
}
main,
nav {
  display: block;
}

.icon-btn, .icon-btn:focus {
  outline: 0;
  border: 0;
  padding: 0;
  margin: 0;
}

.icon {
  transition: color .25s ease;
}
.wrapper {
  padding: 8px 16px;
}
.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}
.nav-item {
  padding: 0 8px;
  color: #c62828;
}
.nav-item.selected,
.nav-item:hover {
  color: #f4511e;
}
.nav-item .icon {
  margin: 3px 4px;
  font-size: 1.1rem;
  display: inline-block;
}
.card-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.card {
  position: relative;
  cursor: pointer;
  width: 33.333%;
  height: 350px;
  padding: 24px;
  box-sizing: border-box;
}
.card-border {
  position: absolute;
  top: 0;
  left: 0;
  border: 36px solid rgba(255,255,255,.8);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: border .25s ease;
}
.card:hover .card-border {
  border-width: 0;
}
.inner-card {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.inner-card > img {
  filter: grayscale(.6);
  transition: filter .25s ease;
  object-position: 50% 50%;
  object-fit: cover;
  height: 350px;
  width: 100%;
}
.card:hover img{
  filter: none;
}
.link {
  cursor: pointer;
  transition: opacity .25s ease;
}
.link:hover {
  opacity: .5;
  cursor: pointer;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 80vh;
  width: 80vw;
  transform: translate3d(-50%, -50%, 0);
  box-shadow: 1px 1px 30px rgba(0,0,0,.4);
  z-index: 4;
  background: #fff;
  padding: 48px;
  display: flex;
	justify-content: center;
}
.modal img {
  object-fit: contain;
  height: 100%;
}
.modal-close {
  position: absolute;
  right: 10px;
  top: 17px;
  height: 24px;
  width: 24px;
}

.modal-background {
  background: rgba(255,255,255,0.8);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
}

.modal-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: opacity .25s ease;
  font-size: 2rem;
}

.modal-nav:hover {
  opacity: .5;
}

.modal-nav.next {
  right: 6px;
}

.modal-nav.prev {
  left: 9px;
}

.invisible {
  visibility: hidden;
}

.card-placeholder {
  background: #eceff1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 2;
}

.card-fave {
  position: absolute;
  z-index: 3;
  bottom: 0px;
  right: 3px;
  width: 30px;
  height: 30px;
}

.icon.fave {
  font-size: 1.6rem;
  color: rgb(198, 40, 40);
  bottom: 30px;
  left: 30px;
  position: absolute;
  z-index: 1;
}
.circular {
  animation: rotate 2s linear infinite;
  height: 60px;
  transform-origin: center center;
  width: 60px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

.about-text {
  max-width: 600px;
  text-align: justify;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: #ff844c;
  }
  60% {
    stroke: #f4511e;
  }
  80%,
  90% {
    stroke: #b91400;
  }
}

@media (max-width: 700px) {
  h1 {
    font-size: 2.5rem;
  }
  .card-wrapper {
    display: block;
    max-height: auto;
  }
  .card {
    width: 100%!important;
    height: auto!important;
    margin-bottom: 8px;
  }

  .inner-card > img {
    filter: none;
  }
  .inner-card {
    margin: 0;
    height: auto;
    border: 1px solid #fff;
  }
  .card-border {
    display: none;
  }
  .nav-text {
    display: none;
  }
  .nav-item .icon {
    font-size: 1.6rem;
  }
}